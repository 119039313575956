import styled from 'styled-components'
import { size } from '../../../utils/styled-components/breakpoints'
import { Container } from '../../../utils'

export const Stats = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    margin: 3rem 0;
    width: 100%;

    @media screen and (max-width: 350px) {
        margin: 0 0 3rem 0;
    }

    @media screen and (max-width: ${size.tablet}) {
        grid-template-columns: 1fr;
        gap: 3rem;
        margin-top: 7rem;
    }

    > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        gap: 1rem;

        > h1 {
            font-size: clamp(1.375rem, -0.375rem + 7.7778vw, 2.25rem);
            font-weight: 600;
            letter-spacing: -0.025em;
            margin-bottom: 0;
            color: #5950ec;
        }

        > p {
            font-size: clamp(0.9rem, -0.375rem + 4.7778vw, 1.125rem);
            font-weight: 300;
            margin-bottom: 0;
            color: #c0bce4;
        }
    }
`

export const Steps = styled.div`
    display: grid;
    grid-template:
        'a b'
        'a c'
        'a d';
    grid-template-columns: min-content auto;
    gap: 1rem;

    svg {
        grid-area: a;
    }

    p {
        height: 4rem;
        margin: 0;
        display: flex;
        align-items: center;

        font-weight: 500;
        color: #0b2242;
    }

    @media screen and (max-width: ${size.laptop}) {
        > p {
            font-size: 0.75rem;
        }
    }
`

export const ColouredBackground = styled.div`
    width: 100%;
    position: relative;
    ${({ purple }) => purple && 'background-color: #5950EC'};
    ${({ purpleLight }) => purpleLight && 'background-color: #EEEEFD'};
    ${({ white }) => white && 'background-color: #ffffff'};
`

export const ComponentContainer = styled.section`
    display: flex;

    justify-content: ${({ justify }) => justify || 'space-evenly'};

    flex-direction: ${({ direction }) => direction || 'row'};
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px`};
    ${({ padding }) => padding && `padding: ${padding}rem 0`};
    ${({ gap }) => gap && `gap: ${gap}rem`};

    position: relative;
    margin: ${({ margin }) => `${margin !== undefined ? margin : '2.5'}rem auto`};
`

export const RelativeContainer = styled(Container)`
    position: relative;
`

export const H1 = styled.h1`
    display: block;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 600 !important;
    letter-spacing: -0.025em;
    line-height: 1.25;
    color: #ffffff;
    white-space: pre-line;
    font-size: clamp(1.375rem, -0.375rem + 7.7778vw, 2.5rem);
    line-height: 1.15;
    font-weight: 600;
    text-align: center;
`

export const H2 = styled.h2`
    display: block;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: ${({ thick }) => (thick ? '700' : '600')} !important;
    letter-spacing: -0.025em;
    line-height: 1.25;
    color: #ffffff;
    white-space: pre-line;
    font-size: clamp(1.125rem, -0.375rem + 7.7778vw, 2rem);
    line-height: 1.15;
    font-weight: 600;
    text-align: center;

    ${({ purple }) => purple && 'color: #5950EC'};
    ${({ agerasGreen }) => agerasGreen && 'color: #01FF94'};

    span {
        ${({ purple }) => purple && 'color: #01FF94'};
    }
`

export const IbanFeaturesGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 2rem;

    padding: 0 3rem;

    @media screen and (max-width: ${size.tablet}) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        gap: 0.75rem;
        padding: 0 0.75rem;
    }
`

export const IbanFeature = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #ffffff;
    gap: 1rem;

    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 1.5rem;
    background-color: #7a73f2;

    padding: 3rem 0;

    @media screen and (max-width: ${size.tablet}) {
        padding: 0.5rem 0;
        gap: 0.5rem;

        svg {
            &:not(.full-width) {
                width: 50%;
            }

            &.notification {
                width: 33%;
            }
        }
    }

    p {
        padding: 0 0.5rem;
    }

    > p.title {
        font-size: clamp(0.9rem, -0.375rem + 3.7778vw, 1.25rem);
        font-weight: 500;
        letter-spacing: -0.025em;
        margin-bottom: 0;
    }

    > p.text {
        font-size: clamp(0.6rem, -0.375rem + 1.7778vw, 1rem);
        font-weight: 300;
        margin-bottom: 0;
    }
`

export const OpenFreeAccountWrapper = styled.div`
    width: 50%;

    margin: 8rem 0 10rem 0;

    padding-right: 0.5rem;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    gap: 1rem;

    h2 {
        text-align: left;
    }

    p {
        color: #ffffff;
    }

    a {
        align-items: center;
    }

    @media screen and (max-width: ${size.tablet}) {
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
        margin-bottom: 30rem;
        margin-top: 3rem;

        a {
            width: 100%;
            padding: 0.5rem 1rem;
            justify-content: center;
        }
    }
`

export const CircleCardWrapper = styled.div`
    position: absolute;
    left: -10rem;
    top: 6rem;
    max-width: 55%;

    @media screen and (max-width: ${size.tablet}) {
        top: unset;
        bottom: -1rem;
        left: -6rem;
        max-width: 100%;
    }

    @media screen and (max-width: 500px) {
        bottom: 3rem;
        max-width: 120%;
    }
`

export const TwoPartContentBlock = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;

    padding: 0 0.5rem;

    > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2.5rem;

        &.with-padding {
            padding-left: 2rem;
        }

        &.custom-padding {
            padding: 6rem 0;

            > img {
                margin: 0;
                max-height: 12rem;
                align-self: flex-start;
            }

            @media screen and (max-width: ${size.tablet}) {
                padding: 6rem 0 2rem 0;

                > img {
                    width: 100%;
                    max-width: 12rem;
                    max-height: unset;
                }
            }
        }

        h2 {
            text-align: left;
        }

        h2,
        p {
            max-width: 80%;
            margin: 0;

            &.purple {
                color: #5950ec;
            }

            &.white {
                color: #ffffff;
            }

            @media screen and (max-width: ${size.laptopL}) {
                max-width: 100%;
            }
        }

        a {
            width: fit-content;
            align-items: center;
        }
    }

    > span {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;

        img {
            /* height: fit-content; */
            margin: 0;
            max-width: 80%;
        }

        &.left-align {
            align-items: flex-start;
        }

        &.custom-positioning {
            justify-content: flex-end;

            img {
                max-width: 65%;
            }
        }

        &.with-animation {
            align-items: center;
        }
    }

    @media screen and (max-width: ${size.tablet}) {
        grid-template-columns: 1fr;
        padding: 0 2rem;
        gap: 4rem;

        > div {
            order: 1 !important;

            &.with-padding {
                padding-left: 0;
            }

            a {
                width: 100%;
                justify-content: center;
            }
        }

        > span {
            order: 0 !important;

            &.custom-positioning {
                order: 2 !important;
                align-items: center;
            }

            img {
                max-width: 100%;
                height: auto;
            }
        }
    }
`

export const SecurityWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @media screen and (max-width: ${size.tablet}) {
        grid-template-columns: 1fr;
    }
`

export const SecurityBlock = styled.div`
    padding: 3rem 2rem 0.5rem 2rem;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    gap: 1rem;

    &:not(:last-of-type) {
        border-right: 1px solid #01ff94;
    }

    svg {
        height: 4rem;
    }

    h3 {
        color: #01ff94;
        margin: 0;
        font-size: 1.125rem;
    }

    p {
        color: #ffffff;
        margin: 0;

        font-size: 0.8rem;
    }

    @media screen and (max-width: ${size.tablet}) {
        border: none !important;

        padding: 3rem 1rem 0.5rem 1rem;

        svg {
            height: 2.5rem;
        }
    }
`

export const CTAWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 3rem;

    padding: 0 0.5rem;
    text-align: center;

    h2 {
        font-weight: 700 !important;
        margin: 0;
    }

    p {
        color: #ffffff;
        margin: 0;
    }

    a {
        align-items: center;
    }
`
