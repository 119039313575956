/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable react/no-array-index-key */
import React, { useRef, useEffect, useState } from 'react'
import tw from 'twin.macro'
import { motion, useAnimation } from 'framer-motion'
import { Layout } from '../components/layout'
import Meta from '../components/layout/meta'
import * as Pricing from '../components/pages/pricing/styled'
import { Button, RegisterButton, Toggle } from '../components/UI'
import FeatureComparison, { useWindowSize } from '../components/pages/pricing/featureComparison'
import { reviewStructuredData } from '../components/layout/meta/schema-org'
import { features } from '../components/pages/pricing/data/features'
import plans from '../components/pages/pricing/data/plan-data'
import * as Banking from '../components/pages/banking/styled'
import { PartnersBlock } from '../components/pages/homepage'

/**
 * Helpers
 */
const fallback = (check, value) => (check ? value : '\u00a0')
const useComma = (string) => string.toString().replace('.', ',')

const Phone = ({ style }) => (
    <svg style={style} xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
        <path d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
    </svg>
)

/**
 * Tailwind styles.
 */
const styles = {
    menu: {
        container: tw`flex mb-4 space-x-2 relative rounded-md bg-white p-2 shadow-md`,
        button: tw`flex-1 antialiased font-normal text-sm tracking-tight py-2 w-full appearance-none border-0 rounded-md focus:ring focus:ring-indigo-500`,
    },
    plan: {
        label: tw`px-4 py-2 mb-4 bg-tellow-purple bg-opacity-10 rounded-lg text-sm antialiased font-medium tracking-tight text-tellow-purple items-center justify-center flex`,
        title: tw`text-2xl capitalize font-bold antialiased mb-0 pt-2 text-tellow-purple`,
        price: tw`text-3xl font-semibold lg:font-medium tracking-tight mb-0 relative text-black flex flex-row gap-1 justify-between`,
        per_month_excl: tw`text-xs font-light text-tellow-purple antialiased pt-0 pb-2 mb-0 mt-0!`,
        extends: tw`p-2`,
        interval: tw`mb-0 text-xs font-medium`,
        vat: tw`mb-0 text-xs antialiased`,
        footer: tw`mt-auto! flex flex-col`,
        list: {
            container: tw`m-0 list-none -ml-2 mb-4`,
            item: tw`font-normal text-xs text-gray-600`,
        },
    },
    disclaimer: tw`text-center bg-gray-50 text-gray-500 p-2 rounded-lg mb-4`,
}

/**
 * Small components / icons.
 */
const BookkeeperHeadInACircle = () => (
    <>
        <Pricing.Bookkeeper />
        <Pricing.Online />
    </>
)

const SwipeIcon = () => (
    <svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
        <g transform="matrix(0.5,0,0,0.5,0,0)">
            <path
                d="M10.608,20.748,6.819,17.655a1.639,1.639,0,0,1-.566-1.676h0a1.638,1.638,0,0,1,2.321-1.068l1.174.587V8.748a1.5,1.5,0,0,1,1.5-1.5h0a1.5,1.5,0,0,1,1.5,1.5v4.5l1.993.333a3,3,0,0,1,2.507,2.959v4.208"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path d="M0.748 7.248L5.998 7.248" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            <path d="M4.498 3.498L0.748 7.248 4.498 10.998" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            <path d="M15.748 7.248L23.248 7.248" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            <path d="M19.498 3.498L23.248 7.248 19.498 10.998" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        </g>
    </svg>
)

/**
 * Main page.
 */
const PricingPage = () => {
    const [isMonthly, setToMonthly] = useState(false)
    const featureRef = useRef(null)
    const controls = useAnimation()
    const timeouts = []
    const windowSize = useWindowSize()
    const isOnTabletOrSmaller = windowSize.width < 1272

    const partnersBlockCopy = {
        slice_type: 'partnersBlock',
        primary: {
            description: {
                text: 'Wij werken samen met belangrijke partners voor ondernemers om jou de beste service te kunnen bieden, maar bedrijven vertrouwen ons ook als boekhoudprogramma voor hun zelfstandig personeel. Iedereen wil toch goed én snel online boekhouden en tijd overhouden voor leuke dingen? ',
            },
            gratis_button: { text: 'Maak gratis gebruik van Tellow' },
            main_title_green: { text: 'die jij ook vertrouwt' },
            main_title_white: { text: 'Vertrouwd door bedrijven' },
        },
    }

    useEffect(() => {
        timeouts.push(
            setTimeout(() => {
                controls.start({ opacity: 1, y: '0px', x: '-50%' })
            }, 500)
        )

        timeouts.push(
            setTimeout(() => {
                controls.start({ opacity: 0, y: '20px', x: '-50%' })
            }, 3500)
        )

        return () => {
            timeouts.forEach((timeout) => clearTimeout(timeout))
        }
    }, [])

    return (
        <Layout>
            <Meta
                keywords={['inexpensive', 'price', 'zzp', 'bookkeeping', 'accounting', 'cost', 'subscription', 'cheap']}
                title="The costs of our online accounting program | Tellow.nl"
                description="Fast online bookkeeping with the online bookkeeping program for freelancers ✅ 100,000+ entrepreneurs aboard ✅ Register for free!"
                path="/en/prijs"
                jsonLD={reviewStructuredData}
            />

            <Pricing.Boundary>
                <Pricing.TellowCardTopRight />
                <Pricing.TellowCardBottomLeft />

                <Pricing.FullWidthCentered>
                    <Pricing.PricingTitle>Kies je pakket</Pricing.PricingTitle>
                    <Pricing.PricingSubtitle>Selecteer wat je nodig hebt, je kunt je pakket later aanpassen</Pricing.PricingSubtitle>
                </Pricing.FullWidthCentered>

                <Pricing.FullWidthCentered>
                    <Pricing.ToggleBoundary>
                        <Toggle options={['Maand', 'Jaar']} value={isMonthly} onChange={(value) => setToMonthly(value)} />
                    </Pricing.ToggleBoundary>
                    <Pricing.Copy onClick={() => setToMonthly(false)} variant="sm">
                        Bespaar tot wel 45% met een jaarlijks abonnement
                    </Pricing.Copy>
                </Pricing.FullWidthCentered>

                {/* <Pricing.OverlayedNotification animate={controls} initial={{ opacity: 0, y: '20px', x: '-50%' }} transition={{ ease: 'easeInOut', duration: 0.65 }}>
                    <SwipeIcon />
                    <p>Swipe / scroll for more</p>
                </Pricing.OverlayedNotification> */}

                <Pricing.PlanContainer layout>
                    {Object.entries(plans).map(([key, plan], idx) => (
                        <Pricing.Plan key={idx} className={idx === 2 ? 'purple-bg' : ''}>
                            {plan.label && <Pricing.Label>{plan.label}</Pricing.Label>}
                            <Pricing.PlanHeader locked={idx === 3 && isMonthly}>
                                <Pricing.Padding>
                                    <h3 css={styles.plan.title}>{key}</h3>

                                    <Pricing.HideOnTablet>
                                        <p className="introduction">{plan.introduction}</p>
                                    </Pricing.HideOnTablet>

                                    <h2 css={styles.plan.price} style={{ minHeight: '50px' }}>
                                        {fallback(plan.active, `€ ${useComma((isMonthly ? plan.priceMonthly : plan.priceYearly) / 100)}`)}
                                        {plan.order !== 1 ? (
                                            <Pricing.PriceExplanation>
                                                / per maand <br /> (exclusief 21% btw)
                                            </Pricing.PriceExplanation>
                                        ) : null}
                                    </h2>

                                    {idx === 3 && <BookkeeperHeadInACircle />}

                                    <Pricing.PlanHeaderCopy>
                                        {plan.order === 1 ? <p className="interval">Geen bankrekening of credit card nodig</p> : null}

                                        {plan.savings && (
                                            <motion.p initial={{ opacity: 0 }} animate={{ opacity: 1 }} className={isMonthly ? 'no-background savings' : 'savings'}>
                                                {isMonthly ? '\u00a0' : idx !== 3 ? `Bespaar € ${plan.savings} per jaar` : 'Jaarabonnement'}
                                            </motion.p>
                                        )}
                                    </Pricing.PlanHeaderCopy>

                                    <Pricing.ShowOnTablet>
                                        <p className="introduction">{plan.introduction}</p>
                                    </Pricing.ShowOnTablet>

                                    {/* Book demo call */}
                                    {idx === 3 && (
                                        <span style={{ minHeight: '3.5rem' }}>
                                            <Button
                                                white
                                                border="purple"
                                                borderWidth="1"
                                                borderColor="#5950EC"
                                                borderRadius={isOnTabletOrSmaller ? '0.75' : '1'}
                                                medium={!isOnTabletOrSmaller}
                                                pricingMobile={isOnTabletOrSmaller}
                                                target="_blank"
                                                href="https://calendly.com/tellow-compleet/compleet-informatie"
                                                style={{ justifyContent: 'center', alignItems: 'center', width: '100%', fontWeight: 500 }}
                                            >
                                                <Phone style={{ height: '1rem', marginRight: '.5rem' }} />
                                                Plan gratis gesprek
                                            </Button>
                                        </span>
                                    )}

                                    {/* Spacer to even out table in case there is no demo button */}
                                    <Pricing.HideOnTablet>{idx !== 3 && <div style={{ minHeight: '3.5rem' }}>&nbsp;</div>}</Pricing.HideOnTablet>

                                    {(idx !== 3 || (idx === 3 && !isMonthly)) && (
                                        <RegisterButton
                                            medium={!isOnTabletOrSmaller}
                                            pricingMobile={isOnTabletOrSmaller}
                                            purple
                                            context="price"
                                            style={{ justifyContent: 'center', marginTop: '.5rem', width: '100%', borderRadius: isOnTabletOrSmaller ? '0.75rem' : '1rem' }}
                                        >
                                            {plan.CTA} <span arrow="true">→</span>
                                        </RegisterButton>
                                    )}

                                    {idx === 3 && isMonthly && (
                                        <Button
                                            medium={!isOnTabletOrSmaller}
                                            pricingMobile={isOnTabletOrSmaller}
                                            disabled
                                            context="price"
                                            onClick={() => setToMonthly(false)}
                                            style={{ justifyContent: 'center', marginTop: '0.5rem', width: '100%', borderRadius: isOnTabletOrSmaller ? '0.75rem' : '1rem' }}
                                        >
                                            Alleen per jaar
                                        </Button>
                                    )}
                                </Pricing.Padding>
                                <Pricing.HideOnTablet>
                                    {idx !== 0 ? <Pricing.MoneyBack>14 dagen niet goed geld terug garantie</Pricing.MoneyBack> : <div style={{ height: '1.5rem' }}>&nbsp;</div>}
                                </Pricing.HideOnTablet>
                            </Pricing.PlanHeader>

                            <Pricing.Padding style={{ height: '100%', paddingBottom: '0.75rem' }}>
                                <Pricing.ListItemHeader>
                                    <div>
                                        <svg viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M4.2698 4.15939C3.7481 4.15939 3.24777 4.36664 2.87888 4.73553C2.50998 5.10443 2.30273 5.60476 2.30273 6.12646V7.11H18.0393V6.12646C18.0393 5.60476 17.832 5.10443 17.4631 4.73553C17.0942 4.36664 16.5939 4.15939 16.0722 4.15939H4.2698Z"
                                                fill="#5950EC"
                                            />
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M18.0393 9.07709H2.30273V13.9948C2.30273 14.5165 2.50998 15.0168 2.87888 15.3857C3.24777 15.7546 3.7481 15.9618 4.2698 15.9618H16.0722C16.5939 15.9618 17.0942 15.7546 17.4631 15.3857C17.832 15.0168 18.0393 14.5165 18.0393 13.9948V9.07709ZM4.2698 13.0112C4.2698 12.7504 4.37343 12.5002 4.55787 12.3158C4.74232 12.1313 4.99249 12.0277 5.25334 12.0277H6.23687C6.49772 12.0277 6.74789 12.1313 6.93234 12.3158C7.11678 12.5002 7.22041 12.7504 7.22041 13.0112C7.22041 13.2721 7.11678 13.5222 6.93234 13.7067C6.74789 13.8911 6.49772 13.9948 6.23687 13.9948H5.25334C4.99249 13.9948 4.74232 13.8911 4.55787 13.7067C4.37343 13.5222 4.2698 13.2721 4.2698 13.0112ZM9.18748 12.0277C8.92663 12.0277 8.67646 12.1313 8.49201 12.3158C8.30756 12.5002 8.20394 12.7504 8.20394 13.0112C8.20394 13.2721 8.30756 13.5222 8.49201 13.7067C8.67646 13.8911 8.92663 13.9948 9.18748 13.9948H10.171C10.4319 13.9948 10.682 13.8911 10.8665 13.7067C11.0509 13.5222 11.1545 13.2721 11.1545 13.0112C11.1545 12.7504 11.0509 12.5002 10.8665 12.3158C10.682 12.1313 10.4319 12.0277 10.171 12.0277H9.18748Z"
                                                fill="#5950EC"
                                            />
                                        </svg>
                                        <p>Bankieren</p>
                                    </div>
                                    {/* <span>NEW</span> */}
                                </Pricing.ListItemHeader>

                                <ul css={styles.plan.list.container} className="bankPerks">
                                    {plan.extendsBank && (
                                        <Pricing.ListItem css={[styles.plan.list.item, tw`text-tellow-purple antialiased`]} style={{ fontSize: '0.6rem' }}>
                                            {fallback(plan.extendsBank, `Alle functies van ${plan.extendsBank} +`)}
                                        </Pricing.ListItem>
                                    )}

                                    {plan.bankPerks?.map((perk, index) => (
                                        <Pricing.ListItem css={[styles.plan.list.item, tw`font-medium`]} key={`${plan}${index}`}>
                                            <svg viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M7.71102 3.40374C7.81807 3.49279 7.82909 3.64767 7.73563 3.74968L5.04009 6.69173C4.99123 6.74507 4.92056 6.77567 4.84626 6.77567C4.77197 6.77567 4.7013 6.74507 4.65243 6.69173L3.57422 5.51491C3.48076 5.41291 3.49178 5.25803 3.59883 5.16898C3.70588 5.07993 3.86842 5.09043 3.96188 5.19243L4.84626 6.1577L7.34797 3.4272C7.44143 3.3252 7.60397 3.3147 7.71102 3.40374Z"
                                                    fill="#1C274C"
                                                />
                                            </svg>

                                            {typeof perk === 'string' && perk}
                                            {typeof perk === 'object' && perk.text}
                                        </Pricing.ListItem>
                                    ))}
                                </ul>

                                <Pricing.ListItemHeader>
                                    <div>
                                        <svg viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M5.08172 18.5433H14.6304C15.4202 18.5433 16.0627 17.9008 16.0627 17.111V7.02123H12.7207C11.9309 7.02123 11.2884 6.37873 11.2884 5.58892V2.24689H5.08172C4.29191 2.24689 3.64941 2.88939 3.64941 3.67919V17.111C3.64941 17.9008 4.29191 18.5433 5.08172 18.5433ZM6.99145 8.96279H12.7207C12.9846 8.96279 13.1981 9.17633 13.1981 9.44022C13.1981 9.70412 12.9846 9.91766 12.7207 9.91766H6.99145C6.72756 9.91766 6.51402 9.70412 6.51402 9.44022C6.51402 9.17633 6.72756 8.96279 6.99145 8.96279ZM6.99145 10.8725H12.7207C12.9846 10.8725 13.1981 11.0861 13.1981 11.35C13.1981 11.6139 12.9846 11.8274 12.7207 11.8274H6.99145C6.72756 11.8274 6.51402 11.6139 6.51402 11.35C6.51402 11.0861 6.72756 10.8725 6.99145 10.8725ZM6.99145 12.7823H12.7207C12.9846 12.7823 13.1981 12.9958 13.1981 13.2597C13.1981 13.5236 12.9846 13.7371 12.7207 13.7371H6.99145C6.72756 13.7371 6.51402 13.5236 6.51402 13.2597C6.51402 12.9958 6.72756 12.7823 6.99145 12.7823ZM6.99145 14.692H10.8109C11.0748 14.692 11.2884 14.9055 11.2884 15.1694C11.2884 15.4333 11.0748 15.6469 10.8109 15.6469H6.99145C6.72756 15.6469 6.51402 15.4333 6.51402 15.1694C6.51402 14.9055 6.72756 14.692 6.99145 14.692Z"
                                                fill="#5950EC"
                                            />
                                            <path d="M12.7216 6.06646H15.7839L12.2441 2.52673V5.58903C12.2441 5.85244 12.4582 6.06646 12.7216 6.06646Z" fill="#5950EC" />
                                        </svg>
                                        <p>Boekhouden</p>
                                    </div>
                                </Pricing.ListItemHeader>

                                <ul css={styles.plan.list.container}>
                                    {plan.extendsPerks && (
                                        <Pricing.ListItem css={[styles.plan.list.item, tw`text-tellow-purple antialiased`]} style={{ fontSize: '0.6rem' }}>
                                            {fallback(plan.extendsPerks, `Alle functies van ${plan.extendsPerks} +`)}
                                        </Pricing.ListItem>
                                    )}

                                    {plan.perks?.map((perk, index) => (
                                        <Pricing.ListItem css={[styles.plan.list.item, tw`font-medium`]} key={`${plan}${index}`}>
                                            <svg viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M7.71102 3.40374C7.81807 3.49279 7.82909 3.64767 7.73563 3.74968L5.04009 6.69173C4.99123 6.74507 4.92056 6.77567 4.84626 6.77567C4.77197 6.77567 4.7013 6.74507 4.65243 6.69173L3.57422 5.51491C3.48076 5.41291 3.49178 5.25803 3.59883 5.16898C3.70588 5.07993 3.86842 5.09043 3.96188 5.19243L4.84626 6.1577L7.34797 3.4272C7.44143 3.3252 7.60397 3.3147 7.71102 3.40374Z"
                                                    fill="#1C274C"
                                                />
                                            </svg>

                                            {typeof perk === 'string' && perk}
                                            {typeof perk === 'object' && perk.text}
                                        </Pricing.ListItem>
                                    ))}
                                </ul>

                                {/* <Pricing.Disclaimer>
                                    {plan.disclaimer?.map((disclaimer, index) => (
                                        <li key={`Disclaimer-${index}`} css={tw`ml-0 antialiased flex items-center text-gray-500 mb-2`}>
                                            <span css={tw`inline-block w-4 font-bold`}>{'*'.repeat(index + 1)}</span>
                                            {disclaimer}
                                        </li>
                                    ))}
                                </Pricing.Disclaimer> */}
                            </Pricing.Padding>
                        </Pricing.Plan>
                    ))}
                </Pricing.PlanContainer>
            </Pricing.Boundary>

            {/* <Pricing.HideOnMobile>
                <Pricing.FullWidthCentered>
                    <Pricing.GoFurtherButtonWrapper onClick={() => document.querySelector('#matrix').scrollIntoView({ behavior: 'smooth' })}>
                        <Pricing.GoFurtherButton>Compare plans</Pricing.GoFurtherButton>
                        <span arrow="true">→</span>
                    </Pricing.GoFurtherButtonWrapper>
                </Pricing.FullWidthCentered>
            </Pricing.HideOnMobile> */}

            <Pricing.FullWidthCentered style={{ marginTop: '4rem' }}>
                <Pricing.PricingTitle>Vergelijk alle functies</Pricing.PricingTitle>
                <Pricing.DownloadDetailsWrapper>
                    <p>Voor een overzicht van alle functies en kosten, klik</p>
                    <Pricing.DownloadDetailsButton href="https://tellow.nl/prijzen/20240813_Overzicht_prijzen_NL.pdf" target="_blank">
                        hier
                    </Pricing.DownloadDetailsButton>
                </Pricing.DownloadDetailsWrapper>
            </Pricing.FullWidthCentered>
            <FeatureComparison id="matrix" features={features} ref={featureRef} isMonthly={isMonthly} />
            <PartnersBlock className="partnersblock" content={partnersBlockCopy} />
            <Banking.ColouredBackground purple>
                <Banking.RelativeContainer>
                    <Banking.ComponentContainer padding={6} margin={0}>
                        <Banking.CTAWrapper>
                            <Banking.H2 style={{ maxWidth: '30ch', lineHeight: '1.75' }}>
                                De bankrekening die je boekhouding doet. Ontdek zelf waarom ruim 100.000 ondernemers je voorgingen.
                            </Banking.H2>
                            <RegisterButton agerasGreen big style={{ padding: '0.5rem 2rem', alignItems: 'center' }}>
                                <span style={{ fontWeight: 600, fontSize: 'clamp(0.875rem, 0.625rem + 1.1111vw, 0.9rem)' }}>Open nu je account &nbsp;</span>
                                <span style={{ fontWeight: 600, fontSize: '1rem' }} arrow="true">
                                    →
                                </span>
                            </RegisterButton>
                        </Banking.CTAWrapper>
                    </Banking.ComponentContainer>
                </Banking.RelativeContainer>
            </Banking.ColouredBackground>
        </Layout>
    )
}

export default PricingPage
